import React, { Component } from 'react'
import Main from '../layouts/main';
import Hero from '../components/hero';

import styles from './about.module.scss';

import GithubIcon from '-!svg-react-loader!./../assets/icons/github.svg';
import LinkedInIcon from '-!svg-react-loader!./../assets/icons/linkedin.svg';
import GitlabIcon from '-!svg-react-loader!./../assets/icons/gitlab.svg';
import StackoverflowIcon from '-!svg-react-loader!./../assets/icons/stackoverflow.svg';
import DownArrowIcon from '-!svg-react-loader!./../assets/icons/down-arrow.svg';


class WhoWeAre extends Component {

  render(){
    return (
      <Main>
        <Hero
          backgroundImage='teamBackground'
          backgroundOverlay=''
        >
          <div className={`${styles.container} ${styles.heroLarge}`}>
            <div className={styles.hero}>
              <h1 className={`${styles.fadeInOne} ${styles.titleHero} ${styles.thickerHero}`}>
                Be Part Of
              </h1>
              <hr className={styles.divider}/>
              <h1 className={`${styles.fadeInTwo} ${styles.titleHero} ${styles.thickerHero}`}>
                Something Great
              </h1>
            </div>
          </div>
        </Hero>
        <div className={styles.sectionTitleContainer}>
          <h2 className={`${styles.sectionTitle} ${styles.fadeInOne}`}>
            Team
          </h2>
        </div>
        <div data-aos="fade-in" data-aos-delay="0" className={styles.gridTeam}>
          <div className={styles.col1}>
            <div className={styles.teamMemberTextContainer}>
              <div className={styles.teamImage}>
                <div className={styles.glen}/>
              </div>
              <div className={styles.teamTitleOverlay}>
                <div className={styles.teamTitleDetails}>
                  <div className={styles.teamTitleContainer}>
                    <div className={styles.teamTitleText}>
                      <h3>
                        Glendon Thompson
                      </h3>
                      <h4>
                        Founder
                      </h4>
                    </div>
                    <div className={styles.teamTitleIcon}>
                      <DownArrowIcon className={styles.dropDownIcon}/>
                    </div>
                  </div>
                  <p>
                    Glendon has a proven track record of launching and selling products in a variety of different industries.
                    He has worked in both data science and engineering being a key contributor in startups and publicly traded companies.
                  </p>
                  <div className={styles.teamMemberLinks}>
                    <a
                      className={styles.socialIconContainer} 
                      href="https://www.linkedin.com/in/glendon-thompson-74878a35/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedInIcon className={styles.socialIcon}/>
                    </a>
                    <a 
                      className={styles.socialIconContainer} 
                      href="https://github.com/gjthompson1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GithubIcon className={styles.socialIcon}/>
                    </a>
                    <a 
                      className={styles.socialIconContainer}
                      href="https://stackoverflow.com/users/3866246/glen-thompson" 
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StackoverflowIcon className={styles.socialIcon}/>
                    </a>
                    <a 
                      className={styles.socialIconContainer}
                      href="https://gitlab.com/gjthompson1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GitlabIcon className={styles.socialIcon}/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.backgroundCloud} ${styles.hasTextWhite}`}>
        <div className={styles.sectionTitleContainer}>
          <h2 className={`${styles.sectionTitle} ${styles.fadeInOne}`}>
            How We're Different
          </h2>
        </div>
          <div className={styles.gridSkew}>
            <div className={styles.colSkew1}>
              <h3 className={styles.fadeInOne}>
                Speed
              </h3>
              <hr className={styles.homeHr}/>
              <p>
                Small and nimble we have a "deal with it now" approach. When time is one of our most valuable assets we often believe that perfect is the enemy of good.
                We focus in full-stack development which means that there are fewer dependencies allowing you to deliver at speed.
              </p>
            </div>
            <div className={styles.colSkew2}>
              <h3 className={styles.fadeInOne}>
                Support
              </h3>
              <hr className={styles.homeHr}/>
              <p>
                We are all serving someone. Whether it's the CEO or the intern, we all have customers.
                With strong communication and a sense of responsibility, things are set up so that you have direct lines of communication to the people that can help.
              </p>
            </div>
            <div className={styles.colSkew3}>
              <h3 className={styles.fadeInOne}>
                Objectivity
              </h3>
              <hr className={styles.homeHr}/>
              <p>
                Elementive focuses on our client's time-to-value goals. We're objective about the vendor landscape and the many build-versus-buy judgments to be made in bringing a complex product to market.
                We know that you have a high demand on your time and so do we. If we aren't going to be the best fit, we are happy to point you in the right direction.
              </p>
            </div>
          </div>
        </div>
      </Main>
    )
  }
}

export default WhoWeAre

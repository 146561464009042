import React from 'react'

import styles from './hero.module.scss'

const Hero = (props) => {
  return (
    <div className={styles.heroBackground}>
      <div className={`${styles[props.backgroundImage]} ${styles.backgroundFadeIn}`}>
        <div className={styles[props.backgroundOverlay]}>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default Hero
